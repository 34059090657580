import React from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      <div
      style={{
        display: `block`,
        marginBottom: 0,
      }}
    >
     
      <section
        style={{
          marginBottom: 0,
        }}
      >
        <p>
          I'm a Basque with love for food experiments, film photography, backpacking, and the {` `}<a href={`https://conquer.earth/kukukaka`}>US National Parks</a> (12 out of 58, a long way to go thankfully).
        </p>
        <p>
          Currently, I am a <b>Senior Product Design Manager at New Relic</b>, a leading Observability platform for developers.</p><p>
          Before it, I worked as a designer <b>helping {` `} <a href={`#msft`}>Microsoft</a> interconnect their Office Online products</b>, at <a href={`#carto`}>CARTO</a> managing the Product and Design teams, or helped <a href={`#tuenti`}>Tuenti</a> transforming the biggest Spanish social network into a full-fledged messaging service. I also worked for several international design studios and created with some friends from scratch our own social startup (and failed).
        </p>
        <p>
          If you want to learn more about my work, feel free to scroll, <del>visit my blog for some thoughts I tried to put together</del>, or check my {` `}<a href={`https://twitter.com/kukukaka02`}>Twitter</a> for random stuff. Thanks.
        </p>
      </section>

      <section 
      id='msft'
      style={{
        marginBottom: 0,
      }}> 
        <p>
          <h2  
            style={{fontSize: '24px', color: ''}}>
              Microsoft Office Online - Yammer
          </h2>
          <h3
            style={{fontSize: '0.8em', fontWeight: '400', color: '', fontFamily: 'Inter, serif', marginTop: -10, marginBottom:24}}>
              <span role="img">🇺🇸 🇬🇧</span> from 2015 to 2018
          </h3>
              I worked as a Senior Designer for Yammer, an enterprise communication tool focused on connecting people across their organization to make better decisions. Yammer was bought by Microsoft in 2012 and had a considerable impact on transforming the internal culture and promoting open source across all Microsoft business units. Meanwhile, the product was starting to find its fit within Microsoft enterprise offering, being integrated as part of Microsoft Office Online. 
        </p>
        <p>
        <br />
        {` `} <a href={`01-Yammer-Group-O365.png`}><img class='lazyload' src={'01-Yammer-Group-O365.png'} alt={"Yammer"} width={'100%'} style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
          <br /><br />
        </p>
        <p>
          <strong>Designing a more integrated Microsoft Office Online suite</strong>
          <br /><br />
          While Yammer HQ is located in San Francisco, I moved to Seattle to be closer to other Microsoft units working from Redmond. My work was focused on creating a seamless experience between Yammer and other Office Online products while helping other teams like Outlook, Skype, Teams, Sharepoint, or OneNote to push in the same direction.
        </p>
        <p>
          I helped to design different touchpoints of that integrated experience: 
          <ul>
            <li
            style={{
              marginTop: 0,
            }}
            >
              Helped to define some common ground to standardize <strong>Microsoft O365 user groups strategy</strong>. Yammer was {` `} <a href={`https://docs.microsoft.com/en-us/yammer/manage-yammer-groups/yammer-and-office-365-groups`}>the first product connecting their group infrastructure</a> to a shared Microsoft service: naming, policies, user management, settings, and error handling.
            </li>
            <li>
              Created a standardize <strong>file experience between Microsoft groups</strong>. I worked with the Sharepoint team to offer a shared component built using React that could be used from any of the different group systems (Outlook, O365, etc.) within the suite.
            </li>
            <li>
              Replaced Yammer chat with <strong>Skype for Business</strong>, a feature heavily used by the team but not much used by our customers and with better technology solutions within the suite.
            </li>
            <li>
            We switched from a custom document solution for groups to embedded <strong>OneNote</strong>.
            </li>
            <li>
            Added <strong>O365 Integrations</strong> into Yammer, a shared service to bring via JSON, updates from services like Trello, Github, Facebook, Mailchimp, etc., to the feed.
            </li>
            <li>
            <strong>Dynamic group membership</strong> set up via Azure Active Directory. Also, we added a shared O365 data classification option at the group level with different security implications for the content. 
            </li>
            <li>
            <strong>Integration into Microsoft Teams</strong>. While Microsoft teams focused on a fast-paced conversation (similar to Slack), Yammer concentrates on a more thoughtful thread like posting. We removed the shared features and designed {` `} <a href={`https://techcommunity.microsoft.com/t5/yammer-blog/yammer-amp-microsoft-teams-ideas-for-using-the-yammer-tab-in/ba-p/360170`}>an embedded experience</a> that allowed the user to jump easily from fast to low paced conversations.
            </li>
          </ul>
        </p>
        <p>
        <br />
        {` `} <a href={`https://dribbble.com/shots/3849844-Yammer-3-Skype`}><img class='lazyload' width={'100%'} src={'../../02-Yammer-Group-Skype.png'} alt={"Yammer"} style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
          <br />
        </p>
        <br />
        <p>
          <strong>Helping to improve Yammer Product Design culture</strong>
        <br /><br />
          At Redmond, I was part of a team formed by +50 Developers and 4 Program Managers, where I was the only Designer. I started the design discipline at the office, {` `}<a href={`../Redmond-Design_Intro.pdf`}>helping the team understand the potential of good design</a> (or sometimes just a bit of design 😅) while being part of the broader design team located in San Francisco. I helped the team to define better project opportunities, {` `}<a href={`https://kukukaka.github.io/yammer-onboarding/explorer/index.html`}>frame better the brainstorms and the concept explorations</a>, run Design Sprints and product workshops, and start using different Service Design techniques to improve the workflow between Product, Research, Engineering, and Design. 
        </p>
        <br />  <br />
        <p style={{
        display: 'flex',
        alignContent: 'top'
      }}>
          <img class='lazyload' width={'100%'} src={'../../Yammer-flow.jpg'} alt={"Yammer"} width='70%'  height='100%' style={{border: ' solid transparent', borderColor: 'white'}}></img>
          <img class='lazyload' width={'100%'} src={'../../Yammer.gif'} alt={"Yammer"} width='30%' height='100%'  style={{border: ' solid transparent', borderColor: 'white' }}></img>
        </p>
        <br />
        <p>
        <strong>Other projects I helped designing</strong>
          <br /><br />
          <ul>
            <li>
              Design a better <strong>onboarding experience</strong>. I ran {` `}<a href={`http://kukukaka.github.io/Yam--Research/team_onboarding.html`}>exploratory research</a> to understand better how onboarding processes work and then proceed to map our onboarding process, including insights from the study. We agreed on several small projects to run experiments and validate impact.
            </li>
            <li>
              <strong>Ipad application</strong> interaction. I helped the team to design the interaction approach for an iPad experience that required the team low maintenance and scalability, so we don't need to redesign it every time we release new features.
            </li> 
            <li>
              To foster several collaborative workflows, we designed a new type of <strong>external group</strong> where people from other organizations or contractors could join. To achieve it, we reworked the user management and group invitation processes, security (both technical and its perception by the customers), and even the pricing.
            </li> 
            <li>
            I {` `}<a href={`../Yammer-Group-Type-UX.pdf`}>Explored</a> different <strong>group types</strong> based on successful use cases identified by research to increase engagement.  
            </li>  
          </ul>
        </p>
      </section>
      <section 
      id='carto'
      style={{
        marginBottom: 0,
      }}>  
        <p>
        <br />  <br />
          <embed src="https://kukukaka.github.io/vl-maps/website/homepage.html" style={{width: '100%', height: '320px', display: 'block', margin: '0 auto',  position: 'relative', zIndex: '0', borderRadius: '8px'}} />    
          <h2  
            style={{fontSize: '36px', color: 'white', position: 'relative', transform: 'translateY(-194px)', textAlign: 'center', zIndex: '1' }}>
              CARTO
          </h2>
          <h3
            style={{fontSize: '0.8em', fontWeight: '400', color: 'white', fontFamily: 'Inter, serif', transform: 'translateY(-194px)', marginTop: -10, marginBottom: -36, position: 'relative', zIndex: '1', textAlign: 'center'}}>
              🇪🇸🇺🇸 since 2018
          </h3>
         </p>
          <p>
            I moved back from the US to Spain to work at CARTO, a spatial data analysis platform. I joined as Principal Designer, and after a couple of months, I got promoted to Head of Design, where I helped the team grow and pivot to be more valuable for the organization, helping to solve deeper technical problems that many times didn't even have an interface. A year after I joined, I was promoted to Head of Product, where I helped to build the team and start moving from a feature-based team to value-focused.</p>
        <br />
        <p>
          <strong>Building a Product team from the ground up</strong>
          <br /><br />
          Coming from a Design background and experience working on different Product teams, I had a clear idea of how a highly technical feature-based team could iterate to have more impact internally, for our customers, and to our business. I divided the work to become a value-based Product team into several phases to test, measure, and improve:
          <ol>
            <li style={{
              marginTop: 0,
            }}>We improved the communication flow with the client-facing teams being more involved and proactive, defining proper communication workflows, and gathering all the feedback into a more accessible tool.</li>
            <li>I started working with the Engineering and Design teams setting up an easy to create and consume <strong>product usage metrics system</strong> using Pub/Sub, BigQuery, and Tableau</li>
            <li>With the help of the Director of Engineering, I improved how projects were defined to reduce the amount of time we lost trying to figure out was what the goal or the plan when the team starts a project. We established a clear differentiation between Discovery & Delivery phases and {` `}<a href={`https://docs.google.com/document/d/1cSPpIBSATCSMBkZg19iC2N7ncVF2c-pMyLbGUOFrv4c/edit?usp=sharing`}>implemented pitches</a> to give more consistency and detail to the discovery output.</li>
            <li>We began to organize better the team pipeline, shared an internal roadmap, and have more visibility over the long term.</li>
            <li><strong>We started working based on Outcomes</strong> over Outputs </li>
            <li>With all the pieces together, we defined a {` `}<a href={`https://docs.google.com/presentation/d/15Po2hLJ93Ih7ZK5pkVYVfIx3T_GcXk1PiPHUjiaxMW8/edit?usp=sharing`}>product opportunity lifecycle</a> and prioritization criteria (based on impact, MRR, effort, etc.). It gives more visibility to the work the team was doing, makes crystal clear the acceptance criteria for new project suggestions, and celebrates achievements.</li>
            <li>I also worked with the Product Managers in three learning paths (Product, GIS, and Data Science) to improve the team knowledge and growth.</li>
          </ol>
        </p>
        <p>
        <br />
        {` `} <a href={`https://carto.com/platform/location-data-streams`}><img class='lazyload' width={'100%'} src={'../06-CARTO-Data-Catalog.gif'} alt={"Yammer"} style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
          <br /><br />
        </p>
        <p>
          <strong>Managing a Design team</strong>
          <br /><br />
          <ul>
            <li>How can we increase the impact of a Design Team in a highly technical data product with not much traditional interface? We decided to be more proactive, filling some skills gaps within the Product Team. We started working more on user research, helping more on strategic definition using design artifacts like blueprints and flows, proposing more design improvements, and began to help and share more metrics.</li>
            <li>How can we communicate the impact better? We started to {` `}<a href={`../CARTO_Design_Update_[July].pdf`}>be more open</a> and made as sure as possible people understood all the spectrum of the work we performed.</li>
            <li>How can we make the team grow at the same time there is personal growth? We define all the skills a Design Team could help with, and based on personal growth interest and team impact; we established {` `} <a href={`https://twitter.com/kukukaka02/status/1220308382403440640`}>individual growth tracks</a>.</li>
            <li>More to come in a blog post soon :)</li>
          </ul>
        </p>
        <p>
        <br />
        {` `} <a href={`https://www.figma.com/file/rMOtRqp6BYsXFxKaA4BwPg/CARTO-System-Map?node-id=349%3A24`}><img class='lazyload' width={'100%'} src={'../08-CARTO-Websiteflow.png'} alt={"Yammer"} style={{border: '0 solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
        <br /> <br /><br /> <br />
        {` `} <a href={`../07-CARTO-Dashboard.gif`}><img class='lazyload' width={'100%'} src={'../07-CARTO-Dashboard.gif'} alt={"Yammer"} style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
        <br /> <br />
        </p>
        <p>
          <strong>Design useful data-centric applications</strong>
          <br /><br />
          <ul>
            <li>I designed new data applications for the NHL and Siemens.</li>
            <li>Led the revamp of the <a href={`https://carto.com/demo/`}>CARTO dashboard</a> o make it less map-centric and make it more useful for users using other parts of our platform like APIs, applications, or data.</li>
            <li>I designed multiple public pages for our website like an <a href={`https://carto.com/demo/`}>explanation of our platform through demos and samples</a>, or the new <a href={`https://carto.com/resources/`}>resources site</a>.</li>
          </ul>
        </p>
        <br />
       <p>
        {` `} <a href={`../04-CARTO-NHL-Map.png`}><img class='lazyload' width={'100%'} src={'../04-CARTO-NHL-Map.png'} alt={"Yammer"} style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
                  <br />
        </p>
      </section>
      <section 
      id='tuenti'
      style={{
        marginBottom: 0,
      }}> 
       <br /> 
          <h2  
            style={{fontSize: '24px', color: ''}}>
              Telefónica - Tuenti
          </h2>
          <h3
            style={{fontSize: '0.8em', fontWeight: '400', color: '', fontFamily: 'Inter, serif', marginTop: -10, marginBottom:24}}>
              🇪🇸 from 2013 to 2015
          </h3>
          <p>
          {` `} <a href={`https://techcrunch.com/2012/07/11/tuenti-telefonicas-answer-to-facebook-and-twitter-opens-up-to-users-worldwide/`}>Bought by Telefónica in 2013</a>, I worked as a <strong>Mobile UX Designer</strong> helping Tuenti (the biggest social network in Spain at the time with more than 10M users) become a mobile-centric service. I worked on the mobile messaging experience designing features like the mobile customer support flows, new generation dialer, bridging calls, or photo albums. I also spend several months <strong>researching user behaviors related to mobile communications</strong> to provide insight to the company leadership.
        </p>
        <p>
        <br />
        {` `} <a href={`../10-Tuenti-Mobile.png`}><img class='lazyload' width={'100%'} src={'../10-Tuenti-Mobile.png'}  style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9', marginBottom: '-36px'}}></img></a>
        {` `} <a href={`../11-Tuenti-Cloudphone_Support-Map.pdf`}><img class='lazyload' width={'100%'} src={'11-Tuenti-Cloudphone_Support-Map.png'}  style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9', marginBottom: '-36px'}}></img></a>      
        {` `} <a href={`../09-Tuenti-Cloudphone_Support-chat_01.png`}><img class='lazyload' width={'100%'} src={'../09-Tuenti-Cloudphone_Support-chat_01.png'}  style={{border: '24px solid #F2F6F9', borderColor: '#F2F6F9'}}></img></a>
        <br />
        </p>
      </section>

      <section 
      id='other'
      style={{
        marginBottom: 0,
      }}> 
       <br /> 
          <h2  
            style={{fontSize: '24px', color: ''}}>
              Others
          </h2>
        
          <p>
          <ul>
            <li>
              I grew as a designer at <strong>{` `} <a href={`https://www.fjord.com/`}>FJORD</a></strong>, both before and after the Accenture acquisition. I design projects for clients like BBVA, Santander, Glovo, Carrefour, and many more; in countries like Spain, Brasil, Italy, or Mexico. We won once App of the Year thanks to our work with BBVA iOS applications, and several apps of the week.</li>
              <li>
                With some friends, we <strong>founded Flipover</strong>, a crowdfunding platform for social projects. We created it from scratch and worked with different NGOs and social workers to improve the impact and the follow up of their work. We released a first version that got some recognition, but some disagreement with the investors pushed us out.</li>
              <li>
                My first contact with the professional design was at <strong>{` `} <a href={`https://www.funkyprojects.com/en/`}>Funky Projects</a></strong>, a small studio in the north of Spain focused on Service Design (most of it non-digital). We created a lot of exciting (ask me!) social experiences for city councils and companies; we created our own products from scratch, and become the first Spanish company member of the Service Design Network</li></ul> 
              <br />
                Thanks for reading :)
        </p>
        </section>
    </div>
    </>
  )
}
